import '@assets/scss/pages/project.scss';

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// components
import Layout from '@src/layouts';
import Video from '@components/atoms/Video';
import Blockquote from '@components/molecules/Blockquote';
// import ButtonExternal from '@components/atoms/buttons/ButtonExternal';
// import ButtonSecondary from '@components/atoms/buttons/ButtonSecondary';
import Column from '@components/organisms/Column';
// import Cta from '@components/molecules/Cta';
import Gallery from '@components/organisms/Gallery';
import HeroProject from '@components/molecules/heros/HeroProject';
import Image from '@components/atoms/Image';
// import ItemArticle from '@components/molecules/items/ItemArticle';
import ListText from '@components/organisms/ListText';

// --------------------------------
// #region data
// --------------------------------

const language = 'en';

const pageClass = 'page-project';

const accent_color = '#265b70';
const footerRelatedLinks = [
	{
		title: 'Rachis Clinic',
		url: '/en/projects/rachis-clinic/',
	},
];

// --------------------------------
// #endregion
// --------------------------------

const ProjectMjfPage = (props) => {
	const data = useStaticQuery(
		graphql`
			query {
				heroImage: file(
					relativePath: {
						eq: "projects/crowsnest/crowsnest_hero.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				heroImageMobile: file(
					relativePath: {
						eq: "projects/crowsnest/crowsnest_hero_mobile.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_0: file(
					relativePath: {
						eq: "projects/avriontx/avrion_brand_dna.png"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1000, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_1: file(
					relativePath: { eq: "projects/crowsnest/crowsnest_1.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_2: file(
					relativePath: { eq: "projects/crowsnest/crowsnest_2.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_3: file(
					relativePath: { eq: "projects/crowsnest/crowsnest_3.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_4: file(
					relativePath: { eq: "projects/crowsnest/crowsnest_4.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_5: file(
					relativePath: { eq: "projects/crowsnest/crowsnest_5.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_6: file(
					relativePath: { eq: "projects/crowsnest/crowsnest_6.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_7: file(
					relativePath: { eq: "projects/crowsnest/crowsnest_7.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_8: file(
					relativePath: { eq: "projects/crowsnest/crowsnest_8.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_9: file(
					relativePath: { eq: "projects/crowsnest/crowsnest_9.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_10: file(
					relativePath: { eq: "projects/crowsnest/crowsnest_10.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	);

	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			accentColor={accent_color}
			location={props.location}
			translations={[
				{
					uri: '/fr/projets/crowsnest-properties-branding/',
					lang: 'fr',
				},
			]}
			title="Crowsnest Properties Branding - By Superhuit"
		>
			<HeroProject
				breadcrumb={{
					title: 'Work',
					link: '/en/projects/',
				}}
				title="Crowsnest Properties"
				image={{
					...data.heroImage.childImageSharp,
				}}
				imageMobile={{
					...data.heroImageMobile.childImageSharp,
				}}
			/>
			<section className="grid grid-content" aria-label="Project">
				<div className="project-intro" data-animation-page>
					<div className="project-summary text-content">
						<p className="p-medium">
							Unearthing real estate projects in their raw state
							and transforming them into high value-added
							diamonds.{' '}
						</p>

						<ListText
							className="element-spacing"
							title="Our services"
							list={[
								'Brand positioning',
								'Branding',
								'Visual Identity',
							]}
						/>
					</div>
					<div className="project-description text-content">
						<h3>What</h3>
						<p>
							Crowsnest is a Californian company that specializes
							in the purchase, valuation and management of
							industrial real estate. The company’s development
							needs called for a complete brand overhaul to more
							effectively translate its distinct characteristics
							and reach its audience remotely.{' '}
						</p>

						<h3>How</h3>
						<p>
							In our strategic branding workshops we pinpointed
							the fundamental DNA of the company, forming the
							basis from which the visual identity and logo were
							born. The creation of this strong and consistent
							visual identity was inspired by New York-esque
							graphic codes, in reference to the main target
							audience. As a result, the positioning and design
							work helps to accelerate the company's development.
						</p>
					</div>
				</div>

				<Video
					className="col-full"
					src="9a3f47b75634263f74623d7a67cb64eb"
					alt=""
				/>

				<Image
					className="col-full"
					{...data.image_1.childImageSharp}
					alt=""
				/>

				<Blockquote
					modifiers="big"
					uptitle=""
					quote="Quality buildings in strategic locations"
					more="The logo symbol represents Crowsnest Properties' ability to uncover the best property locations - comparable to rare raw pearls - and transform them into diamonds. The three-dimensional shape of the symbol conveys the company’s three main areas of expertise."
				/>

				<Image
					className="col-full"
					{...data.image_2.childImageSharp}
					alt=""
				/>

				{/* 12 col + [Image + Image + Image + Image] + gutter */}
				<Gallery className="col-full grid-spacing" modifiers="gutter">
					<Image {...data.image_3.childImageSharp} alt="" />
					<Image {...data.image_4.childImageSharp} alt="" />
				</Gallery>

				<Image
					className="col-full grid-spacing"
					{...data.image_5.childImageSharp}
					alt=""
				/>

				{/* TODO */}
				{/* <Blockquote
					modifiers="big"
					uptitle="Customer Testimonial"
					quote="We are very impressed with Superhuit’s work. We engaged them to design corporate logos, branding and materials for prospective business partners."
					more="Their creative process walked us through the emotions of our brand and what we want to clearly convey to our customers and partners. We were blown away when we received 30 inbound indications of interest which is taking our business to a new level of performance. Superhuit is creative, organized and very receptive to our needs and we are looking forward to continuing to work with them!"
					cite="Rett Frost – …, Crowsnest Properties"
				/> */}

				<Column modifiers="left">
					<div className="column__media text-content">
						<Image {...data.image_6.childImageSharp} alt="" />
						<Image {...data.image_7.childImageSharp} alt="" />
					</div>

					<div className="column__text column__sticky text-content">
						<h3>Rock solid, powerful branding</h3>
						<p>
							The applied identity reinterprets elegant graphical
							screening codes in an application that is
							undoubtedly playful but, above all, striking. The
							simplicity of the symbol offers the benefit of being
							immediately recognizable and easy to integrate
							across different media contexts.
						</p>
					</div>
				</Column>

				<Column>
					<div className="column__text column__sticky text-content">
						<h3>
							The simplicity of the symbol offers the benefit of
							being immediately recognizable.{' '}
						</h3>
						<p>
							And easy to integrate across different media
							contexts.
						</p>
					</div>
					<div className="column__media text-content">
						<Image {...data.image_9.childImageSharp} alt="" />
						<Image {...data.image_10.childImageSharp} alt="" />
					</div>
				</Column>

				{/* <Cta
					subscribe
					text="Be the first to know when we release a new project"
					cta="Subscribe"
					currentPage="Project"
				/> */}
			</section>
		</Layout>
	);
};

export default ProjectMjfPage;
